import React from "react";
//import $ from "jquery";
import "../App.css";
import { NavLink } from "react-router-dom";
import fbSvg from "../Resources/svg/fb_gray.svg";
import waSvg from "../Resources/svg/wa.svg";
import phoneSvg from "../Resources/svg/phone_gray.svg";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer-section-main-div">
        <div className="footer-body restrict-width">
          <div className="footer-column">
            <div className="footer-column-element">
              <NavLink to="/tuning" exact>
                <span style={{ fontSize: "1rem" }}>Tuning</span>
              </NavLink>
            </div>
            <div className="footer-column-element">
              <NavLink to="/airbag" exact>
                <span style={{ fontSize: "1rem" }}>Airbag</span>
              </NavLink>
            </div>
            <div className="footer-column-element">
              <NavLink to="/chei" exact>
                <span style={{ fontSize: "1rem" }}>Chei</span>
              </NavLink>
            </div>
            <div className="footer-column-element">
              <NavLink to="/alteservicii" exact>
                <span style={{ fontSize: "1rem" }}>Alte servicii</span>
              </NavLink>
            </div>
          </div>
          <div className="footer-column">
            <div className="media-icons">
              <a
                href="https://www.facebook.com/mircea.chira"
                target="_blank"
                rel="noopener noreferrer"
                className="media-icon"
              >
                <img src={fbSvg} alt="facebook" />
              </a>

              <a
                href="https://wa.me/40799768298"
                target="_blank"
                rel="noopener noreferrer"
                className="media-icon"
              >
                <img src={waSvg} alt="whatsapp" />
              </a>
            </div>
            <div className="header-middle-item">
              <a href="tel:0040799768298">
                <img src={phoneSvg} alt="phone" />
                <span className="phone-number">0040 799 768 298</span>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-body restrict-width footer-column-element">
          <span>
            Copyright 2019 - {new Date().getFullYear()} BHP Motor Tuning
          </span>
        </div>
      </div>
    );
  }
}

export default Footer;
