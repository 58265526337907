class _Add {
  ImgUploadOnSuccess(result) {
    console.log(result);
  }
  constructor(
    id,
    title,
    description,
    price,
    currency,
    category = "autoturism",
    slideshow = false,
    body = "",
    ac = "",
    buildYear = 1900,
    drive = "",
    fuelType = "",
    kW = 0,
    hp = 0,
    mileage = "",
    used = "",
    tva = false,
    isNew = false,
    createdAt,
    img0 = "",
    img1 = "",
    img2 = "",
    img3 = "",
    img4 = "",
    img5 = "",
    img6 = "",
    img7 = "",
    img8 = "",
    img9 = "",
    deleted = false,
    capacity = "50",
    noSeats = 4,
    noDoors = 5,
    emission = 6,
    noOwners = 1,
    parkAid = "",
    color = "",
    interior = "",
    aux = "[]"
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.price = price;
    this.currency = currency;
    this.category = category;
    this.slideshow = slideshow;
    this.body = body;
    this.ac = ac;
    this.buildYear = buildYear;
    this.drive = drive;
    this.fuelType = fuelType;
    this.kW = kW;
    this.hp = hp;
    this.mileage = mileage;
    this.used = used;
    this.tva = tva;
    this.isNew = isNew;
    this.createdAt = createdAt;
    this.img0 = img0;
    this.img1 = img1;
    this.img2 = img2;
    this.img3 = img3;
    this.img4 = img4;
    this.img5 = img5;
    this.img6 = img6;
    this.img7 = img7;
    this.img8 = img8;
    this.img9 = img9;
    this.deleted = deleted;
    this.capacity = capacity;
    this.noSeats = noSeats;
    this.noDoors = noDoors;
    this.emission = emission;
    this.noOwners = noOwners;
    this.parkAid = parkAid;
    this.color = color;
    this.interior = interior;
    this.aux = aux;
  }
  static returnArray = data => {
    let state = [];
    for (let index in data) {
      const item = data[index].data();
      /* console.log(
        "ITEM: " + index,
        item.createdAt,
        " xxx ",
        item.createdAt && item.createdAt !== undefined
          ? new Date(item.createdAt)
          : null
      ); */
      state.push(
        new _Add(
          item.id,
          item.title,
          item.description,
          item.price,
          item.currency,
          item.category,
          item.slideshow,
          item.body,
          item.ac,
          item.buildYear,
          item.drive,
          item.fuelType,
          item.kW,
          item.hp,
          item.mileage,
          item.used,
          item.tva,
          item.isNew,
          item.createdAt,
          item.img0,
          item.img1,
          item.img2,
          item.img3,
          item.img4,
          item.img5,
          item.img6,
          item.img7,
          item.img8,
          item.img9,
          item.deleted,
          item.capacity,
          item.noSeats,
          item.noDoors,
          item.emission,
          item.noOwners,
          item.parkAid,
          item.color,
          item.interior,
          item.aux
        )
      );
    }
    return state;
  };

  static getFileNameFromFullPath = filePath => {
    if (filePath.indexOf("?") < 0) return null;
    filePath = filePath.split("?")[0];
    if (filePath.indexOf("/") < 0) return null;
    filePath = filePath.split("/");
    filePath = filePath[filePath.length - 1];
    filePath = decodeURIComponent(filePath);
    return filePath;
  };
  static getThumbPath = fullPath => {
    const sP = decodeURIComponent(fullPath).split("/");
    fullPath = "thumb_" + sP[sP.length - 1];
    /*fullPath = fullPath
      .replace(/\?/g, "%3F")
      .replace(/=/g, "%3D")
      .replace(/&/g, "%26");*/
    sP[sP.length - 2] = sP[sP.length - 2] + "%2F" + fullPath;
    sP.splice(sP.length - 1, 1);
    //console.log(sP.join("/"));
    return sP.join("/");
  };
  static returnObjFromArray = (data, id) => {
    const list = this.returnArray(data);
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) return list[i];
    }
    return null;
  };

  static returnPageArray = data => {
    let state = [];
    for (let index in data) {
      const item = data[index].data();
      /* console.log(
        "ITEM: " + index,
        item.createdAt,
        " xxx ",
        item.createdAt && item.createdAt !== undefined
          ? new Date(item.createdAt)
          : null
      ); */
      state.push(item);
    }
    return state;
  };
  static returnPageFromArray = (data, id) => {
    const list = this.returnPageArray(data);
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) return list[i];
    }
    return null;
  };
}
export default _Add;
