import React from "react";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";

import { Route, BrowserRouter } from "react-router-dom";

import firebaseSDK from "./Settings/FirebaseConfig";
import PageLabel from "./Components/PageLabel";

import { connect } from "react-redux";
import { setAuth, overwriteListAdds } from "./Actions";
// import PageTuning from "./Components/Page/PageTuning";
// import PageChei from "./Components/Page/PageChei";
// import PageAlte from "./Components/Page/PageAlte";
// import PageAirbag from "./Components/Page/PageAirbag";

import Slideshow from "./Components/Slideshow/Slideshow";
import HomeInfo from "./Components/HomeInfo/HomeInfo";
import PageContact from "./Components/Contact";

import { overwriteListCategories } from "./Actions/category";
import CategoryList from "./Components/Category/CategoryList";
import Category from "./Components/Category/Category";

const images = [
  "https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/website%2F1.jpg?alt=media&token=cf2b4cd8-8fc3-4031-b688-66b73d0e7bd2",
  "https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/website%2F2.jpg?alt=media&token=7430af60-a36d-437e-8a32-1680a3d5ae96",
  "https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/website%2F3.jpg?alt=media&token=3f202754-e286-416f-b6fa-9c3a6ef17203",
  "https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/website%2F4.jpg?alt=media&token=f7d497f9-ace3-4218-8301-78eb932d7da1",
  "https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/website%2F5.jpg?alt=media&token=5b3b4522-ca30-45a8-be95-3f23310343b0",
  "https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/website%2F6.jpg?alt=media&token=dbdf367b-f4a1-4f61-83a5-cc79363963e3",
  "https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/website%2F7.jpg?alt=media&token=3951c550-d15a-45cd-9640-a3c88c93e1ed",
  "https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/website%2F8.jpg?alt=media&token=d447a481-025e-49a6-8d65-cca698889729",
];

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      adds: [
        {
          id: 0,
          title: "",
          decription: "",
        },
      ],
      errorMessage: "",
    };
  }

  errorDisplay = (errorMessage) => {
    //set state.errorMesage to App.js
    this.setState({ errorMessage: errorMessage });
  };

  componentDidMount() {
    //connect to a collection + getAll items
    //firebaseSDK.getAll("Add", this.props.overwriteListAdds, this.errorDisplay);
    firebaseSDK.getAll(
      "Categories",
      this.props.overwriteListCategories,
      this.errorDisplay
    );
    //firebaseSDK.onAuthStateChanged(this.props.setAuth, console.log);
    //console.log("INIT APP");
  }
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Route path="/" component={Header} />

          <Route
            path="/"
            exact
            render={() => {
              return (
                <div className="home-page-main-div">
                  <Slideshow slideData={images} shuffle={true} />
                  <div className="adds-header">
                    <div className="restrict-width">
                      <PageLabel label1="BHP" label2="MOTOR TUNING" />
                    </div>
                  </div>
                  <HomeInfo />
                  <PageContact />
                </div>
              );
            }}
          />

          {/* <Route path="/tuning" exact component={PageTuning} />
          <Route path="/airbag" exact component={PageAirbag} />
          <Route path="/chei" exact component={PageChei} />
          <Route path="/alteservicii" exact component={PageAlte} /> */}
          <Route
            path="/tuning"
            exact
            render={() => <CategoryList category="Tuning" />}
          />
          <Route
            path="/airbag"
            exact
            render={() => <CategoryList category="Airbag" />}
          />
          <Route
            path="/chei"
            exact
            render={() => <CategoryList category="Chei" />}
          />
          <Route
            path="/alteservicii"
            exact
            render={() => <CategoryList category="AlteServicii" />}
          />
          <Route path="/serviciu/:id" exact component={Category} />
          <Route path="/contact" component={Contact} />
          <Route path="/" component={Footer} />
        </div>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setAuth: (page) => dispatch(setAuth(page)),
  overwriteListAdds: (page) => dispatch(overwriteListAdds(page)),
  overwriteListCategories: (page) => dispatch(overwriteListCategories(page)),
});
export default connect(null, mapDispatchToProps)(App);
