import React from "react";
import { Slide } from "react-slideshow-image";

const properties = {
  duration: 10000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  arrows: false,
  onChange: (oldIndex, newIndex) => {
    //console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  }
};

const shuffleImages = array => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

const Slideshow = ({ slideData, shuffle }) => {
  let ind = 0;
  let data = slideData;
  if (shuffle) {
    data = shuffleImages(slideData);
  }
  //console.log(data);
  return (
    <div className="slide-container">
      <Slide {...properties}>
        {data.map(image => {
          return (
            <div className="each-slide" key={ind++}>
              <div style={{ backgroundImage: `url(${image})` }}>
                <span>Slide 1</span>
              </div>
            </div>
          );
        })}
      </Slide>
    </div>
  );
};

export default Slideshow;
