import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { selectCategory } from "../../Actions/category";

const Category = ({ item, admin, classAddon }) => {
  const _classes = ["add-list-view"];
  _classes.push(classAddon);

  const _date = new Date(item.createdAt).toLocaleString().toString();
  //console.log(_date);
  //console.log(this.getShortDateFormat(item.createdAt));

  if (!item) return "no Items";
  const noImg =
    "https://firebasestorage.googleapis.com/v0/b/bhp-market.appspot.com/o/content%2Fsquare-image.png?alt=media&token=81e956ff-dc7e-4036-8057-c16721bfeb6d";
  return (
    <div className={_classes.join(" ")}>
      <span className="add-title">
        {item.title}
        <i style={{ color: "red" }}>
          {item.deleted ? " - Anunt Inactiv -" : ""}
        </i>
      </span>
      <div className="add-row">
        <div className="add-image-box">
          <img
            className="img-thumb"
            src={item.img0 ? item.img0 : noImg}
            alt={item.title}
          />
        </div>
        <div className="add-details-box">
          <div className="add-price-box">
            <div>
              <small>
                {admin
                  ? "Data Anunt: " + _date
                  : item.description.substring(0, 300) + " ..."}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

class CategoryList extends React.Component {
  render() {
    //console.log(this.props);
    const isAdmin = this.props.admin;
    let _index = 0;
    const { categories } = this.props;
    return (
      <div className="adds-main restrict-width">
        {categories.map(item => {
          _index++;
          let classAddon =
            _index % 2 ? "lighter-background-color" : "darker-background-color";
          classAddon += item.isNew ? " badge-new-car" : "";

          return (
            <Link
              className="auth-page-main-div"
              to={!isAdmin ? `/serviciu/${item.id}` : "/admin/category/edit"}
              key={item.id}
              onClick={() => this.props.selectCategory(item)}
            >
              <Category item={item} classAddon={classAddon} admin={isAdmin} />
            </Link>
          );
        })}
      </div>
    );
  }
}

// (state) = all REDUX STORE
const mapStateToProps = (state, props) => {
  //filterset autoturism/autoutilitar

  let _adds = state.categories.filter(_add => _add.category === props.category);
  //console.log(state, props.category, _adds);
  if (!props.admin) {
    _adds = _adds.filter(_add => _add.deleted === false);
  }

  //console.log(" LIST:", _adds);
  return { categories: _adds };
  // this.props === {adds: [...]}
};

export default connect(mapStateToProps, { selectCategory })(CategoryList);
