//import _Add from "../Models/_Add";

/*
const adds_default = [
  new _Add(
    1,
    "titlu test 1",
    "description 1",
    10000,
    "EUR",
    "autoturism",
    true
  ),
  new _Add(
    2,
    "titlu test 2",
    "description 2",
    8000,
    "EUR",
    "autoutilitar",
    true
  )
];
*/
export const selectedPageReducer = (selectedAdd = null, action) => {
  if (action.type === "PAGE_SELECTED") return action.payload;
  return selectedAdd;
};

export const selectedAddReducer = (selectedAdd = null, action) => {
  if (action.type === "ADD_SELECTED") return action.payload;
  return selectedAdd;
};

export const addReducer = (state = [], action) => {
  //console.log(action);
  switch (action.type) {
    case "REMOVE_LIST_ADD":
      let dState = [...state];
      for (let x = 0; x < dState.length; x++) {
        if (dState[x].id === action.payload) {
          dState.splice(x, 1);
          break;
        }
      }
      return dState;
    case "LOAD_SELECTED_ADD":
      return state.selectedAdd;
    case "OVERWRITE_LIST_ADDS":
      return action.payload;
    case "GET_LIST_ADD":
      //console.log(state);
      let sState = [...state];
      for (let x = 0; x < sState.length; x++) {
        if (sState[x].id === action.payload) {
          return sState[x];
        }
      }
      return null;
    case "UPDATE_LIST_ADD":
      let nState = [...state];
      let bFound = false;
      for (let x = 0; x < nState.length; x++) {
        if (nState[x].id === action.payload.id) {
          nState[x] = action.payload;
          bFound = true;
          break;
        }
      }
      if (!bFound) {
        nState.push(action.payload);
      }
      return nState;
    default:
      return state;
  }
};
