export const selectedCategoryReducer = (selectedAdd = null, action) => {
  if (action.type === "CATEGORY_SELECTED") return action.payload;
  return selectedAdd;
};

export const categoryReducer = (state = [], action) => {
  //console.log(action);
  switch (action.type) {
    case "REMOVE_LIST_CATEGORY":
      let dState = [...state];
      for (let x = 0; x < dState.length; x++) {
        if (dState[x].id === action.payload) {
          dState.splice(x, 1);
          break;
        }
      }
      return dState;
    case "LOAD_SELECTED_CATEGORY":
      return state.selectedCategory;
    case "OVERWRITE_LIST_CATEGORIES":
      return [...action.payload];
    case "GET_LIST_CATEGORY":
      //console.log(state);
      let sState = [...state];
      for (let x = 0; x < sState.length; x++) {
        if (sState[x].id === action.payload) {
          return sState[x];
        }
      }
      return null;
    case "UPDATE_LIST_CATEGORY":
      let nState = [...state];
      let bFound = false;
      for (let x = 0; x < nState.length; x++) {
        if (nState[x].id === action.payload.id) {
          nState[x] = action.payload;
          bFound = true;
          break;
        }
      }
      if (!bFound) {
        nState.push(action.payload);
      }
      return nState;
    default:
      return state;
  }
};
