import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
import _Add from "../Models/_Add";
import _Category from "../Models/_Category";

class FirebaseSDK {
  constructor() {
    if (!firebase.apps.length) {
      //avoid re-initializing
      firebase.initializeApp({
        apiKey: "AIzaSyCi3-H9nNfY2YUXDbUsIhuYG9fLE7JlHBo",
        authDomain: "bhp-market.firebaseapp.com",
        databaseURL: "https://bhp-market.firebaseio.com",
        projectId: "bhp-market",
        storageBucket: "bhp-market.appspot.com",
        messagingSenderId: "870015828819",
        appId: "1:870015828819:web:e71cfd62d613f751"
      });
    }
  }

  // Firebase Authentication SingIn - SignOut
  // ----------------------------------------------------

  signInWithEmailAndPassword = async (
    user,
    success_callback,
    failed_callback
  ) => {
    await firebase
      .auth()
      .signInWithEmailAndPassword(user.username, user.password)
      .then(userInfo => {
        success_callback(userInfo);
      })
      .catch(err => failed_callback(err));
  };

  signOut = async (success_callback, failed_callback) => {
    //console.log("signOUt");
    await firebase
      .auth()
      .signOut()
      .then(success_callback, err => failed_callback(err));
  };

  execUserAuthenticated = (success_callback, failed_callback, objParams) => {
    const user = this.checkUserStatus();
    if (user) {
      success_callback(user, objParams);
    } else {
      failed_callback(objParams);
    }
  };

  currentUser = () => {
    const user = firebase.auth().currentUser;
    //console.log("currentUser:", user);
    return user;
  };

  onAuthStateChanged = (success_callback, failed_callback) => {
    firebase.auth().onAuthStateChanged(
      authUser => {
        // need to update Redux state with userStatus valid or null
        success_callback(authUser);
      },
      err => failed_callback(err)
    );
  };

  // Firebase Database
  // ----------------------------------------------------
  delete = async (
    collectionName,
    values,
    success_callback,
    failed_callback
  ) => {
    const db = this.connectToCollection(collectionName);
    //const db = firebase.database().ref(collectionName);
    await db
      .doc(values.id)
      .delete()
      .then(success_callback(), err => failed_callback(err));
  };

  set = async (collectionName, values, success_callback, failed_callback) => {
    const db = this.connectToCollection(collectionName);
    //const db = firebase.database().ref(collectionName);
    await db
      .doc(values.id)
      .set(values)
      .then(success_callback(values), err => failed_callback(err));
  };
  connectToCollection(collectionName) {
    const db = firebase.app().firestore();
    return db ? db.collection(collectionName) : null;
  }

  switchCollectionModel = (collectionName, doc) => {
    switch (collectionName) {
      case "Add":
        return _Add.returnArray(doc.docs);
      case "Categories":
        return _Category.returnArray(doc.docs);
      default:
        return null;
    }
  };

  getAll = async (collectionName, success_callback, failed_callback) => {
    const tableConnection = this.connectToCollection(collectionName);
    await tableConnection.get().then(
      doc => {
        //console.log("data", doc.docs);

        success_callback(this.switchCollectionModel(collectionName, doc));
      },
      err => failed_callback(err)
    );
  };

  getById = async (id, collectionName, success_callback, failed_callback) => {
    const tableConnection = this.connectToCollection(collectionName);
    await tableConnection.get().then(
      doc => {
        //console.log("data", doc.docs);
        success_callback(this.switchCollectionModel(collectionName, doc));
      },
      err => failed_callback(err)
    );
  };

  getPageById = async (
    id,
    collectionName,
    success_callback,
    failed_callback
  ) => {
    const tableConnection = this.connectToCollection(collectionName);
    await tableConnection.get().then(
      doc => {
        //console.log("data", doc.docs);
        success_callback(_Add.returnPageFromArray(doc.docs, id));
      },
      err => failed_callback(err)
    );
  };
  // Firebase Storage
  // ----------------------------------------------------

  deleteStorage = async (filePath, success_callback, failed_callback) => {
    var storage = firebase.storage();
    // Create a storage reference from our storage service
    var storageRef = storage.ref();
    // Create a reference to the file to delete
    var delRef = storageRef.child(filePath);

    // Delete the file
    delRef
      .delete()
      .then(function() {
        success_callback();
      })
      .catch(function(error) {
        // Uh-oh, an error occurred!
        failed_callback(error);
      });
  };
  postStorage = async (
    fileData,
    next_callback,
    success_callback,
    failed_callback
  ) => {
    //create storage ref {fileData.file, fileData.folder, fileData.fileName}
    const storageRef = firebase
      .storage()
      .ref(fileData.folderName + "/" + fileData.fileName);
    //upload file
    const task = storageRef.put(fileData.file);
    //update the status of upload
    task.on(
      "state_changed",
      snapshot => {
        let percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        next_callback(percentage);
      },
      err => failed_callback(err),
      function() {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        task.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          console.log("File available at", downloadURL);
          success_callback(downloadURL);
        });
      }
    );
  };
}
const firebaseSDK = new FirebaseSDK();
export default firebaseSDK;
