import React from "react";
import "./HomeInfo.css";
import tuningSvg from "../../Resources/svg/tuning.svg";
import airbagSvg from "../../Resources/svg/airbag.svg";
import cheiSvg from "../../Resources/svg/chei.svg";

const HomeInfo = () => {
  return (
    <div className="home-info">
      <div>
        <p className="title">
          Partenerul dvs. principal pentru ajustarea cipurilor și îmbunătățirea
          performanței.&nbsp;
        </p>
        <p style={{ padding: "0 16px" }}>
          De asemenea, să fim entuziasmați de potențialul suplimentar pe care îl
          putem obține de la motorul vehiculului dvs. prin reglajul nostru
          special creat. Multe teste și experiență vastă cu o mare varietate de
          modele de vehicule ne permit să vă oferim îmbunătățiri profesionale
          pentru o gamă largă de modele de vehicule prin optimizarea
          caracteristică.
        </p>
      </div>
      <div className="home-info-row">
        <div className="home-info-col">
          <img src={tuningSvg} alt="" />
          <p className="title">Tuning</p>
          <p className="text">
            Ne putem confrunta cu peste 12 ani de experiență în domeniul
            reglării cipurilor și, prin urmare, suntem partenerul dvs. competent
            în Germania și în întreaga lume.
          </p>
        </div>
        <div className="home-info-col">
          <img src={airbagSvg} alt="" />
          <p className="title">Airbag</p>
          <p className="text">
            Mulți producători de vehicule folosesc astăzi blocarea V-max. De
            asemenea, le putem personaliza individual, în funcție de cerințele
            clienților, sau le putem elimina complet.
          </p>
        </div>
        <div className="home-info-col">
          <img src={cheiSvg} alt="" />
          <p className="title">Chei</p>
          <p className="text">
            Puține companii oferă o gamă largă de servicii pe care le oferim:
            chei, anularea imo, anularea erorii de pornire, repararea filtrelor
            de particule și multe altele. Vă vom ajuta să o găsiți.
          </p>
        </div>
      </div>
    </div>
  );
};
export default HomeInfo;
