//action creator
export const selectAdd = add => {
  return {
    type: "ADD_SELECTED",
    payload: add
  };
};

export const setAuth = userInfo => {
  return {
    type: "SET_AUTH",
    payload: userInfo
  };
};

export const overwriteListAdds = adds => {
  return {
    type: "OVERWRITE_LIST_ADDS",
    payload: adds
  };
};

export const updateListAdd = add => {
  return {
    type: "UPDATE_LIST_ADD",
    payload: add
  };
};
export const removeListAdd = id => {
  return {
    type: "REMOVE_LIST_ADD",
    payload: id
  };
};
export const loadSelectedAdd = () => {
  return {
    type: "LOAD_SELECTED_ADD",
    payload: null
  };
};
export const selectPage = add => {
  return {
    type: "PAGE_SELECTED",
    payload: add
  };
};
export const getAddById = id => {
  return {
    type: "GET_LIST_ADD",
    payload: id
  };
};
