import React from "react";
import Slideshow from "../Slideshow/Slideshow";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../Page/Page.css";
import { selectCategory } from "../../Actions/category";

class Category extends React.Component {
  getById() {
    //console.log(this.props);
    const { id } = this.props.match.params;
    if (!this.props.selectedCategory && id) {
      const item = this.props.categories.find(
        cat => cat.id === id && cat.deleted === false
      );
      //console.log(item);
      if (item) this.props.selectCategory(item);
    }
  }
  render() {
    const { selectedCategory } = this.props;
    //console.log(selectedCategory);
    if (!selectedCategory) {
      this.getById();
      return null;
    }
    let images = [];
    for (let x = 0; x < 10; x++) {
      if (selectedCategory["img" + x] !== "") {
        images.push(selectedCategory["img" + x]);
      }
    }
    return (
      <div className="home-info add-container">
        <div className="add-title">{selectedCategory.title}</div>

        {images.length === 0 ? null : images.length < 2 ? (
          <img
            className="slide-container-single-image"
            src={images[0]}
            alt=""
          />
        ) : (
          <Slideshow slideData={images} shuffle={false} />
        )}
        <div className="add-description">{selectedCategory.description}</div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  //console.log(state);
  return {
    selectedCategory: state.selectedCategory,
    categories: state.categories
  };
};
const mapDispatchToProps = dispatch => ({
  selectCategory: page => dispatch(selectCategory(page))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Category));
